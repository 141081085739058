import { Component, createRef } from 'react';
import { bindActionCreators, compose } from 'redux';
import { connect } from 'react-redux';
import Link from 'next/link';
import { cx } from '@emotion/css'
import { isEmail } from 'validator';
import _ from 'lodash';
import { Grid } from '@mui/material';
import { withStyles } from 'tss-react/mui';
import withThemedLayoutNoSession from '../hocs/withThemedLayoutNoSession';
import { loginUser } from '../slices/authSlice';
import GreenButton from '../../../shared_components/buttons/Green';
import GreyButton from '../../../shared_components/buttons/Grey';
import { Container, Title } from '../components/PublicPages';
import { getAndClearReferrerFromBrowser } from '../utils/returnToHandler';
import CircularProgressWithBackdrop from '../../../shared_components/CircularProgressWithBackdrop';
import { AppointmentsApi, StaffApi } from '../../../client_http_api';
import Select from '../components/Select/TextField';
import { makeOptionWithField } from '../../../shared_client_utils/formUtils';
import { rootPath } from '../../../client_routes';
import TextField from '../../../shared_components/Input/TextField';
import { setCriticalStatus } from '../slices/appointmentsSlice';
import OrangeButton from '../../../shared_components/buttons/Orange';

const styles = (theme) => ({
  input: {
    marginTop: theme.spacing(10 / 4),
  },
  forgotContainer: {
    margin: theme.spacing(1.5, 0, 3, 0),
    textAlign: 'end',
  },
  link: {
    color: theme.palette.primary.main,
    textDecoration: 'none',
    cursor: 'pointer',
    '&:hover': {
      color: "#FF7147",
    },
  },
  signUp: {
    marginTop: theme.spacing(5),
    textAlign: 'center',
  },
  showField: {
    display: 'flex',
  },
  hideField: {
    display: 'none',
  },
  buttonsBox: {
    flexWrap: 'nowrap',
    justifyContent: 'center',
  },
  backButton: {
    marginRight: theme.spacing(2),
  },
});

const nextButtonComponents = {
  email: ({ email, onClickContinue, customerPortalLink, stripeRedirect, isCreateSubscription }) => {
    if (!customerPortalLink && !isCreateSubscription) {
      return (
        <GreenButton
          fullWidth
          variant="contained"
          data-testid="emailContinueButton"
          size="medium"
          type="submit"
          disabled={email.length === 0 || !isEmail(email)}
          onClick={onClickContinue}
        >
          Continue
        </GreenButton>
      )
    } else if (isCreateSubscription || customerPortalLink) {
      const text = isCreateSubscription ? 'Create a subscription' : 'Subscription details'
      return (
        <OrangeButton
          disabled={!customerPortalLink}
          variant="contained"
          size="medium"
          sx={{width: '70%', padding: '6px 22px'}}
          onClick={stripeRedirect}
        >
          {text}
        </OrangeButton>
      )
    }
  },
  password: ({ password, loading, onClickLogIn }) => (
    <GreenButton
      fullWidth
      variant="contained"
      data-testid="loginButton"
      size="medium"
      type="submit"
      disabled={password.length < 8 || loading}
      onClick={onClickLogIn}
    >
      Log in
    </GreenButton>
  ),
  staffId: (props) => {
    const {
      selectedStaffId,
      loading,
      onClickUseBusiness,
      useBusinessButton,
    } = props;

    return (
      <GreenButton
        fullWidth
        ref={useBusinessButton}
        variant="contained"
        size="medium"
        type="submit"
        disabled={!selectedStaffId || loading}
        onClick={onClickUseBusiness}
      >
        Use business
      </GreenButton>
    );
  },
};
class LogIn extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: '',
      errorEmail: '',
      password: '',
      errorPassword: '',
      currentField: 'email',
      loading: false,
      userStaff: [],
      selectedStaffId: null,
      customerPortalLink: null,
      isCreateSubscription: false,
    };
    this.emailInput = createRef();
    this.passwordInput = createRef();
    this.useBusinessButton = createRef();
    this.handleChangeUserField = this.handleChangeUserField.bind(this);
    this.handleSelectBusiness = this.handleSelectBusiness.bind(this);
    this.onClickContinue = this.onClickContinue.bind(this);
    this.onClickLogIn = this.onClickLogIn.bind(this);
    this.onClickUseBusiness = this.onClickUseBusiness.bind(this);
    this.onClickBack = this.onClickBack.bind(this);
    this.stripeRedirect = this.stripeRedirect.bind(this);
  }

  async componentDidMount() {
    this.emailInput.current.focus();
  }

  handleChangeUserField = (field) => ({ target: { value } }) => {
    const { errorEmail, customerPortalLink, isCreateSubscription } = this.state;
    if (field === 'email' && errorEmail?.length > 0 && (customerPortalLink || isCreateSubscription)) {
      this.setState({ [field]: value, errorEmail: '', customerPortalLink: null, isCreateSubscription: false});
    } else {
      this.setState({ [field]: value });
    }
  }

  handleSelectBusiness({ value }) {
    this.setState({ selectedStaffId: value });
  }

  onClickBack() {
    const { currentField } = this.state;

    switch(currentField) {
      case 'password': {
        return this.setState({ currentField: 'email', customerPortalLink: null, isCreateSubscription: false });
      }
      case 'staffId': {
        return this.setState({ currentField: 'password' });
      }
      default: {
        return this.setState({ currentField: 'email' });
      }
    }
  }

  onClickContinue() {
    const { email } = this.state;

    if (email.length === 0) {
      this.setState({ errorEmail: 'Can\'t be blank' });
      return;
    }
    if (!isEmail(email)) {
      this.setState({ errorEmail: 'Enter a valid email' });
      return;
    }

    this.setState({ currentField: 'password' }, () => {
      this.passwordInput.current.focus();
    });
  }

  async onClickLogIn() {
    const { email, password } = this.state;
    const {
      router,
      loginUser
    } = this.props;

    if (password.length < 8) {
      this.setState({
        errorPassword: 'Password must be at least 8 characters long',
      });
      return;
    }
    this.setState({ loading: true });

    try {
      const userStaff = await StaffApi.fetchUserStaff(email, password);
      if (_.isEmpty(userStaff)) {
        return this.setState({
          errorEmail: 'User not found',
          password: '',
          currentField: 'email',
          loading: false,
        }, () => this.emailInput.current.focus());
      } else if (userStaff.length > 1) {
        const preparedUserStaff = userStaff.map(
          makeOptionWithField({ labelColumn: 'businessName' }),
        );

        return this.setState({
          selectedStaffId: preparedUserStaff[0].id,
          userStaff: preparedUserStaff,
          currentField: 'staffId',
          loading: false,
        }, () => this.useBusinessButton.current.focus());
      }

      const currentStaff = userStaff[0];
      const { payload = {} } = await loginUser({
        email,
        password,
        staffId: currentStaff.id,
      });

      const { token, currentStaff: logStaff } = payload
      if (token && logStaff) {
        const { criticalStatusCount = 0 } = await AppointmentsApi.fetchStatusCounters(
          {...(logStaff.roleName === "Staff" ? {staffId: logStaff.id} : {})},
          { token }
        )
        setCriticalStatus(criticalStatusCount)
      }

      const referrer = getAndClearReferrerFromBrowser();
      if (referrer) {
        router.replace(referrer)
      } else {
        router.replace(rootPath())
      }
    } catch (error) {
      console.log('onClickLogIn_ERROR', error)
      const { body = {}} = error
      const { customerPortalLink, isCreateSubscription } = body;
      this.setState({
        errorEmail: error.message,
        customerPortalLink,
        isCreateSubscription,
        password: '',
        currentField: 'email',
        loading: false,
      }, () => !customerPortalLink && !isCreateSubscription ? this.emailInput.current.focus() : null
      );
    }
  }

  async onClickUseBusiness() {
    const { email, password, selectedStaffId } = this.state;
    const {
      router,
      loginUser,
      setCriticalStatus
    } = this.props;

    this.setState({ loading: true });

    try {
      const { payload = {} } = await loginUser({
        email,
        password,
        staffId: selectedStaffId,
      });

      const { token, currentStaff: logStaff } = payload
      if (token && logStaff) {
        const { criticalStatusCount = 0 } = await AppointmentsApi.fetchStatusCounters(
          {...(logStaff.roleName === "Staff" ? {staffId: logStaff.id} : {})},
          { token }
        )
        setCriticalStatus(criticalStatusCount)
      }

      const referrer = getAndClearReferrerFromBrowser();
      if (referrer) {
        router.replace(referrer)
      } else {
        router.replace(rootPath())
      }
    } catch (error) {
      console.log('onClickUseBusiness_ERROR', error)
      const { body = {}} = error
      const { customerPortalLink, isCreateSubscription } = body;
      this.setState({
        errorEmail: error.message,
        customerPortalLink,
        isCreateSubscription,
        password: '',
        currentField: 'email',
        loading: false,
      }, () => !customerPortalLink && !isCreateSubscription ? this.emailInput.current.focus() : null
      );
    }
  }

  stripeRedirect = () => {
    const { customerPortalLink } = this.state
    window.location.href = customerPortalLink
  }

  render() {
    const {
      currentField,
      email,
      errorEmail,
      password,
      errorPassword,
      loading,
      userStaff,
      selectedStaffId,
    } = this.state;
    const { classes } = this.props;
    const selectedStaff = userStaff.find(({ id }) => id === selectedStaffId);

    const NextButtonComponent = nextButtonComponents[currentField];

    return (
      <Container data-testid="loginPage">
        <Title>Log in</Title>

        <form
          onSubmit={(event) => event.preventDefault()}
          autoComplete="off"
        >
          <TextField
            required
            fullWidth
            inputRef={this.emailInput}
            id="email"
            className={cx(
              classes.input,
              currentField === 'email' ? classes.showField : classes.hideField,
            )}
            autoComplete="email"
            type="email"
            placeholder="Enter your e-mail"
            label="E-mail"
            value={email}
            onChange={this.handleChangeUserField('email')}
            error={!!errorEmail}
            helperText={errorEmail}
          />

          <TextField
            required
            fullWidth
            id="password"
            inputRef={this.passwordInput}
            className={cx(
              classes.input,
              currentField === 'password' ? classes.showField : classes.hideField,
            )}
            autoComplete="current-password"
            type="password"
            placeholder="Enter a password"
            label="Password"
            value={password}
            onChange={this.handleChangeUserField('password')}
            error={!!errorPassword}
            helperText={errorPassword}
          />

          <Select
            id="staffId"
            label="Choose Business"
            onChange={this.handleSelectBusiness}
            options={userStaff}
            value={selectedStaff}
            className={cx(
              classes.select,
              currentField === 'staffId' ? classes.showField : classes.hideField,
            )}
          />

          <div className={classes.forgotContainer}>
            <Link href="/forgot">
              <span className={classes.link}>Forgot password?</span>
            </Link>
          </div>

          <Grid container className={classes.buttonsBox}>
            {currentField !== 'email' && (
              <GreyButton
                variant="text"
                size="medium"
                className={classes.backButton}
                onClick={this.onClickBack}
              >
                Back
              </GreyButton>
            )}

            <NextButtonComponent
              {...this.state}
              {...this.props}
              useBusinessButton={this.useBusinessButton}
              onClickContinue={this.onClickContinue}
              onClickLogIn={this.onClickLogIn}
              onClickUseBusiness={this.onClickUseBusiness}
              stripeRedirect={this.stripeRedirect}
            />
          </Grid>

          <div className={classes.signUp}>
            {`Don't have an account?`} <Link href="/register"><span className={classes.link}>Sign up</span></Link> for free!
          </div>
        </form>

        <CircularProgressWithBackdrop loading={loading} />
      </Container>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  loginUser: bindActionCreators(loginUser, dispatch),
  setCriticalStatus: bindActionCreators(setCriticalStatus, dispatch),
});

export default compose(
  withThemedLayoutNoSession,
  connect(null, mapDispatchToProps),
)(withStyles(LogIn, styles));
