import { Grid, Typography } from '@mui/material';
import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()((theme) => ({
  root: {
    marginBottom: theme.spacing(4),
    justifyContent: 'center',
  },
  title: {
    paddingBottom: theme.spacing(1/2),
    borderBottom: `2px solid ${theme.palette.primary.main}`,
  },
}));

const Title = ({ className, children }) => {
  const {classes} = useStyles();

  return (
    <Grid container className={classes.root}>
      <Typography
        variant="h4"
        className={className}
        classes={{
          root: classes.title,
        }}
      >
        {children}
      </Typography>
    </Grid>
  );
};

Title.defaultProps = {
  className: '',
};

export default Title;
