import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()((theme) => ({
  logo: {
    height: theme.spacing(4),
  },
}));

const Logo = () => {
  const {classes} = useStyles();

  return <img src="/logo_new.png" className={classes.logo} alt="logo"/>;
};

export default Logo;
