import { Component } from 'react';
import { connect } from 'react-redux';
import Router from 'next/router';

const withoutSession = (Page) => {
  class NoSessionPage extends Component {
    componentDidMount() {
      const { auth: { token } } = this.props;
      if (token) {
        Router.replace('/');
      }
    }

    render() {
      return <Page {...this.props} />;
    }
  }

  const mapStateToProps = ({ auth }) => ({ auth });

  return connect(mapStateToProps)(NoSessionPage);
};

export default withoutSession;
