import { Grid, Paper, AppBar, Toolbar } from '@mui/material';
import { makeStyles } from 'tss-react/mui';

import Logo from './Logo';
import { navbarHeightMultiplier } from '../../../../shared_client_utils/theme';

const useStyles = makeStyles()((theme) => ({
  appBar: {
    height: theme.spacing(navbarHeightMultiplier),
    alignItems: 'center',
    justifyContent: 'center',
  },
  logo: {
    justifyContent: 'center',
  },
  container: {
    minWidth: theme.spacing(42),
    margin: '8px auto',

    [theme.breakpoints.down('md')]: {
      width: '100%',
      maxWidth: '96%',
    },
  },
  content: {
    border: `1px solid ${theme.palette.primary.main}`,
    padding: theme.spacing(4),

    [theme.breakpoints.down('md')]: {
      padding: theme.spacing(2),
    },

    [theme.breakpoints.up('sm')]: {
      minWidth: theme.spacing(45),
      maxWidth: theme.spacing(60),
    },
  },
}));

const Container = ({ children, classes: parentClasses, ...otherProps }) => {
  const {classes, cx} = useStyles();

  return (
    <Grid container {...otherProps}>
      <AppBar color="inherit" position="sticky" className={classes.appBar}>
        <Toolbar>
          <Grid container className={classes.logo}>
            <Logo />
          </Grid>
        </Toolbar>
      </AppBar>

      <div
        className={cx(
          classes.container,
          parentClasses.container,
        )}
      >
        <Paper
          elevation={3}
          className={classes.content}
          classes={{
            root: parentClasses.content,
          }}
        >
          {children}
        </Paper>
      </div>
    </Grid>
  );
};

Container.defaultProps = {
  classes: {
    container: '',
    content: '',
  },
};

export default Container;
